import { NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'

import { CoreModule } from '@src/core/core.module'
import { AppRoutesModule, routes } from './app-routes.module'
import { AppComponent } from './app.component'

import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { provideRouter, RouteReuseStrategy } from '@angular/router'
import { BluetoothSerial } from '@awesome-cordova-plugins/bluetooth-serial/ngx'
import { IonicModule, IonicRouteStrategy } from '@ionic/angular'
import { provideIonicAngular } from '@ionic/angular/standalone'
import { MessageService } from 'primeng/api'
import { ToastModule } from 'primeng/toast'
@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, IonicModule.forRoot(), AppRoutesModule, CoreModule, ToastModule],
  bootstrap: [AppComponent],
  providers: [
    provideRouter(routes),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular({ mode: 'md', keyboardHeight: 320 }),
    provideHttpClient(withInterceptorsFromDi()),
    MessageService,
    BluetoothSerial,
  ],
})
export class AppModule { }
