import { Component, OnInit } from '@angular/core'
import { TitleService } from '@src/core/services/title.service'
import { BluetoothService } from '@src/services/bluetooth.service'
import { Platform } from '@ionic/angular'
import { BleClient } from '@capacitor-community/bluetooth-le'

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    public titleService: TitleService,
    private bluetoothService: BluetoothService,
    private platform: Platform,
  ) {
    this.initializeApp();
  }

  async ngOnInit(): Promise<void> {
    this.bluetoothService.connectToScanner()
  }

  async initializeApp() {
    await this.platform.ready();
    if (this.platform.is('android')) {
      await this.initializeBluetooth();
    }
  }

  private async initializeBluetooth() {
    try {
      await BleClient.initialize({
        androidNeverForLocation: true
      });

    } catch (error) {
      console.error('Erreur d\'initialisation Bluetooth:', error);
    }
  }
}
