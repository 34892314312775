import { HttpClient } from '@angular/common/http'
import { Component } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { AuthService } from '@src/core/services/auth.service'
import { Environment } from '@src/environments/environment'
import { setStorageJSON } from '@src/helpers/storage.helper'
import { BluetoothService } from '@src/services/bluetooth.service'
import { addIcons } from 'ionicons'
import { logOutSharp, personSharp } from 'ionicons/icons'
import { KeycloakService } from 'keycloak-angular'
import { KeycloakProfile } from 'keycloak-js'
import { Subscription } from 'rxjs'
@Component({
  selector: 'app-ionic-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {
  private urlPath = Environment.API_PATH

  public loggedIn: boolean = false
  public user: KeycloakProfile = {}
  public me: any = {}
  public headerMenuItems: Array<{ label: string; icon: string; action?: () => void }> = []
  private routerSubscription?: Subscription
  private currentRoute?: string

  private noBackButtonRoutes = ['/accueil', '/operations/operation/synthese']

  constructor(
    private readonly auth: AuthService,
    private router: Router,
    private http: HttpClient,
    private keycloakService: KeycloakService,
    private bluetoothService: BluetoothService
  ) {
    addIcons({ personSharp, logOutSharp })
  }

  async ngOnInit(): Promise<void> {
    this.loggedIn = await this.auth.isLoggedIn()
    // get current route for the first time
    this.currentRoute = this.router.url
    this.routerSubscription = this.router.events.subscribe((data) => {
      if (data instanceof NavigationEnd) {
        // log the current route
        this.currentRoute = data.url
      }
    })

    if (this.loggedIn) {
      this.user = await this.auth.loadUserProfile()
      this.http.get(`${this.urlPath}mobile/me`).subscribe((data) => {
        this.me = data
        setStorageJSON('currentUser', data)
      })
      this.headerMenuItems = [
        {
          label: this.user.email ?? 'Utilisateur',
          icon: 'person-sharp',
        },
        {
          label: 'Déconnexion',
          icon: 'log-out-sharp',
          action: () => this.onLogout(),
        },
      ]
    }
  }

  public onLogout(): void {
    const isAppMobil = /Mobi|Android/i.test(navigator.userAgent)
    if (isAppMobil) {
      window.history.replaceState(null, '', '/');
      localStorage.removeItem('KC_TOKEN')
      this.keycloakService.logout().then(() => {
        window.location.reload()
        this.bluetoothService.disconnect()
      })
    } else {
      localStorage.removeItem('KC_TOKEN')
      this.keycloakService.logout(window.location.origin)
    }
  }

  public showBackButton(): boolean {
    return !this.noBackButtonRoutes.includes(this.currentRoute ?? '')
  }

  public previous(): void {
    window.history.back()
  }

  get isBluetoothConnected(): boolean {
    return this.bluetoothService.isDeviceConnected()
  }

  async toggleBluetoothConnection() {
    try {
      if (this.isBluetoothConnected) {
        await this.bluetoothService.disconnect();
      } else {
        await this.bluetoothService.connectToScanner();
      }
    } catch (error) {
      console.error('Erreur Bluetooth:', error);
    }
  }
}
